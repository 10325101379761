import styles from "./styles.module.css";
import {PagegrowlerFunctions} from "@pagegrowler_nextjs";
import { formatAuthorNames } from '@/shared/functions/formatAthorNames'

const TemaEspecial = (props) => {
  const { title } = props;
  const CLIPPING = "3_2_630x420";
  
  let mainContent = !props.content ? [] : [...props.content];
  
  const pagegrowlerFunctions = PagegrowlerFunctions();
  const highlightedContent = mainContent.length > 0 ? [mainContent.shift()] : undefined;
  const content = mainContent;

  const getImage = (element, crop, width, height) => {
    if (element.images && element.images.length > 0) {
      if (element.images && element.images[0] && element.images[0].value) {
        element = {
          images: [{
            clippings: element.images[0].value.clippings || [],
            title: element.images[0].value.title || "",
            _id: element.images[0]._id,
          }],
        };
      }
    } else {
      element.images = undefined;
    }
    return PagegrowlerFunctions()
      .getFullImageObject(
        element,
        crop,
        process.env.NEXT_PUBLIC_IMAGE_DOMAIN, {
          width,
          height
        }
      );
  }

  const getAuthors = (authors) => {
    let authorsList = []
    if (authors != undefined && authors?.length) {
			const authorName = formatAuthorNames(authors[0].name);
      authors.length > 1
        ? authors
            .slice(0, 2)
            .map((element) => {
				      const elementName = formatAuthorNames(element.name);
              return authorsList.push(
                element.slug
                  ? `<a href=/autor/${element.slug}>${elementName}</a>`
                  : elementName
              )
            })
        : authorsList.push(
            authors[0].slug
              ? `<a href=/autor/${authors[0].slug}>${authorName}</a>`
              : authorName
          );
    }
    return authorsList.join(' y ');
  }

  return (
    <div className={`${styles["tema-especial-parent"]}`}>
      {title && title != '' && <h3 className={`${styles["tema-especial-title"]}`}>{title}</h3>}
      <div className={`${styles["tema-especial-container"]}`}>
        {highlightedContent &&
          highlightedContent.map((element, id) => {
            const { authors, title, subtitle, url } = element;

           const img = getImage(element,CLIPPING, 630, 420);
           const parsedAuthors = getAuthors(authors);

            return (
              <div key={element + id} data-testid='tema-especial-left-image' className={`${styles['tema-especial-left-image']}`} style={{ backgroundImage: `url(${img.url})`, backgroundSize: 'cover' }}>
                <a href={url} />
                <div className={`${styles["tema-especial-left-text"]}`}>
                  <a href={url}>
                    <h2>{subtitle}</h2>
                    <h1>{title}</h1>
                  </a>
                  {parsedAuthors && (
                    <div className={`${styles["tema-especial-left-authors"]}`} 
                      dangerouslySetInnerHTML={{ 
                        __html: 'Por ' + parsedAuthors 
                      }}
                    />
                  )}
                </div>
              </div>
            );
          })}
        <div className={`${styles["tema-especial-right-list"]}`}>
          {content && content.map((element, id) => {
            const { url, title, subtitle } = element;
            return (
              <article key={element+id} className={`${styles["tema-especial-right-item"]}`}>
                <a href={url} key={element + id}>
                  <h2><em>{subtitle}</em>{title}</h2>
                </a>
                <hr />
              </article>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TemaEspecial;
